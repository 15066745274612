<template>
    <div class="feature">
        <div class="feature__label">{{ label }}</div>
        <div class="feature__value">
            <slot name="default">{{value}}</slot>
        </div>
    </div>
</template>

<script>
export default {
    name: "Features",
    props: {
        label: {
            type: String,
            default: ''
        },
        value: {
            type: String,
            default: ''
        },
    }
}
</script>

<style></style>
