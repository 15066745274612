/**
 *
 * @param value Число для которого нужно вернуть сроку
 * @param arg  Массив "аргумент, аргумента, аргументов"
 * @returns {*|string}
 */
export function pluralize(value, ...arg) {
    let args = [...arg];
    if (args.length === 3) {
        let number = Math.abs(parseInt(value)),
            a = number % 10,
            b = number % 100;

        if ((a === 1) && (b !== 11)) {
            return args[0]
        } else if ((a >= 2) && (a <= 4) && ((b < 10) || (b >= 20))) {
            return args[1]
        } else {
            return args[2]
        }
    } else {
        throw new Error(`This arg ${args} not 3`)
    }
}

/**
 *
 * @param chars
 * @param size
 * @returns {string}
 */
export function generatePassword(chars = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789@/./+/-/_.', size) {
    let password = '';
    const length = chars.length;

    for (let i = 0; i < size; i++) {
        let num = Math.floor(Math.random(length - 1));
        password += chars.substr(num, 1)
    }
    return password
}

/**
 *
 * @param chars
 * @param id
 * @returns {string}
 */
export function letter(chars = 'АБВГДЕЁЖЗИЙКЛМНОПРСТУФХЦЧШЩЪЫЬЭЮЯ', id) {
    return id ? chars.substr(id, 1) : '';
}

/**
 *
 * @param str
 * @returns {string}
 */
export function translate(str) {
    /**
     * Преобразует спроку в транстил
     * @type {Map<string, string>}
     */
    const ru = new Map([
        ['а', 'a'], ['б', 'b'], ['в', 'v'], ['г', 'g'], ['д', 'd'], ['е', 'e'],
        ['є', 'e'], ['ё', 'e'], ['ж', 'j'], ['з', 'z'], ['и', 'i'], ['ї', 'yi'], ['й', 'i'],
        ['к', 'k'], ['л', 'l'], ['м', 'm'], ['н', 'n'], ['о', 'o'], ['п', 'p'], ['р', 'r'],
        ['с', 's'], ['т', 't'], ['у', 'u'], ['ф', 'f'], ['х', 'h'], ['ц', 'c'], ['ч', 'ch'],
        ['ш', 'sh'], ['щ', 'shch'], ['ы', 'y'], ['э', 'e'], ['ю', 'u'], ['я', 'ya'],
    ]);

    let clear = str.replace(/[ъь]+/g, '');
    return Array.from(clear)
        .reduce((s, l) =>
            s + (
                ru.get(l)
                || ru.get(l.toLowerCase()) === undefined && l
                || ru.get(l.toLowerCase()).toUpperCase()
            ), '');
}

export function cyrillic(str) {
    /**
     * Преобразует спроку в транстил
     * @type {Map<string, string>}
     */
        // const ru = new Map([
        //         ['a', 'ф'], ['b', 'и'], ['c', 'с'], ['d', 'в'], ['e', 'у'], ['f', 'а'],
        //         ['g', 'п'], ['h', 'р'], ['i', 'ш'], ['j', 'о'], ['k', 'л'], ['l', 'д'], ['m', 'ь'],
        //         ['n', 'т'], ['o', 'щ'], ['p', 'з'], ['q', 'й'], ['r', 'к'], ['s', 'ы'], ['t', 'е'],
        //         ['u', 'г'], ['v', 'м'], ['w', 'ц'], ['x', 'ч'], ['y', 'н'], ['z', 'я'],
        //         ['', ''], ['', ''], ['', ''], ['', ''], ['', ''], ['', ''], ['', ''], ['', ''], ['', ''],
        //     ]);

    const ru = new Map([["q", "й"],
            ["w", "ц"], ["e", "у"], ["r", "к"], ["t", "е"], ["y", "н"], ["u", "г"], ["i", "ш"], ["o", "щ"], ["p", "з"],
            ["[", "х"], ["{", "Х"], ["]", "ъ"], ["}", "Ъ"], ["|", "/"], ["`", "ё"], ["~", "Ё"],
            ["a", "ф"], ["s", "ы"], ["d", "в"], ["f", "а"], ["g", "п"], ["h", "р"], ["j", "о"], ["k", "л"], ["l", "д"],
            [";", "ж"], [":", "Ж"], ["'", "э"], ["\"", "Э"],
            ["z", "я"], ["x", "ч"], ["c", "с"], ["v", "м"], ["b", "и"], ["n", "т"], ["m", "ь"],
            [",", "б"], ["<", "Б"], [".", "ю"], [">", "Ю"],["/", "."], ["?", ","], ["@", "\""], ["#", "№"], ["$", ";"], ["^", ":"], ["&", "?"]
        ])


    return Array.from(str)
        .reduce((s, l) =>
            s + (
                ru.get(l)
                || ru.get(l.toLowerCase()) === undefined && l
                || ru.get(l.toLowerCase()).toUpperCase()
            ), '');
}

/**
 *
 * @param value
 * @returns {*}
 */
export function getNumber(value) {
    /**Возвращает стороку содержащую цифры*/
    return parseInt(value.replace(/\D/g, ""));
}

/**
 *
 * @param value
 * @returns {string}
 */
export function getPhoneLink(value) {
    /**Возвращает телефон без тире и скобок*/
    let reg = "()/- "
    for(const char of reg){
        value = value.replace(new RegExp(`\\${char}`,'g'), "")
    }
    return value
}


export function priceFormat(value) {
    /**Возвращяет число в денежном формате*/
    // return value.format(0, 3, ' ', ',')
    return new Intl.NumberFormat('ru-RU').format(value)
}

export function capitalize(str) {
    /**Возвращяет str с большой буквы*/
    return str[0].toUpperCase() + str.slice(1);
}

export function booleanFromString(value) {
    /**Возвращяет bool из строки*/
    if (typeof value === 'string') {
        if (value === 'false') {
            return false
        } else if (value === 'true') {
            return true
        }
    }

    return value;
}

