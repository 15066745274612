<template>
    <div class="banner">
        <div class="banner__wrapper">
            <h5 v-if="title" class="banner__title">{{ title }}</h5>
            <div v-if="content"
                 v-html="content"
                 class="banner__content"
            ></div>
            <v-button class="btn_three"
                      :href="url">{{ label ? label : 'Подробнее' }}
            </v-button>
        </div>
        <v-image v-if="image"
                 :src="image"
                 class="banner__image"></v-image>
    </div>
</template>

<script>
import Button from "@components/ui/forms/Button";
import Image from "@components/ui/Image";

export default {
    name: 'Banner',
    components: {
        'v-button': Button,
        'v-image': Image,
    },
    props: {
        id: {
            type: Number,
            default: null
        },
        title: {
            type: String,
            default: null
        },
        content: {
            type: String,
            default: null
        },
        url: {
            type: String,
            default: null
        },
        label: {
            type: String,
            default: null
        },
        image: {
            type: String,
            default: null
        },
    },
}
</script>

<style lang="less">
.banner {
    color: var(--white);
    width: 300px;
    height: 300px;
    background: var(--primary-darker);
    border-radius: var(--border-radius-lg);
    box-shadow: var(--shadow-md);
    padding: 2rem;
    box-sizing: border-box;
    position: relative;
    overflow: hidden;

    &__wrapper {
        position: relative;
        z-index: 3;
    }

    &__title {
        font-weight: bold;
        line-height: var(--line-height);
    }

    &__content {
        position: relative;
        z-index: 3;
        margin-bottom: 1rem;
    }

    &__image {
        position: absolute !important;
        inset: 0;
        width: 100%;
        height: 100%;
        opacity: .3;
        z-index: 1;

        img{
            object-fit: cover;
        }
    }
}
</style>
