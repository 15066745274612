<template>
    <v-block
        title="Новые объекты"
        class="block_padding"
        url="/catalog/"
        textBtn="Все объекты"
        :items="offers"
        :observe="true"
        @onInit="initHandler"
    >
        <template v-slot:preloader>
            <div class="list">
                <div class="list__content list__content_three">
                    <v-skeleton-cart></v-skeleton-cart>
                    <v-skeleton-cart></v-skeleton-cart>
                    <v-skeleton-cart></v-skeleton-cart>
                </div>
            </div>
        </template>

        <template v-slot:item="slotProps">
            <v-offer
                :name=slotProps.item.name
                :location="slotProps.item.location"
                :link=slotProps.item.link
                :type=slotProps.item.type
                :price=slotProps.item.price
                :period=slotProps.item.period
                :price_per_meter=slotProps.item.price_per_meter
                :rooms=slotProps.item.rooms
                :area=slotProps.item.total_area
                :floor=slotProps.item.floor
                :floors=slotProps.item.floors_total
                :additionally=slotProps.item.additionally
                :thumbnail=slotProps.item.thumbnail
                :images="slotProps.item.images"
                :key="slotProps.item.id"
            ></v-offer>
        </template>
    </v-block>
</template>

<script>
import {onMounted, ref} from "vue";

import Offer from "@components/catalog/offers/Offer";
import Block from "@components/content/Block";
import Card from "@components/skeletons/Card";

import useOffers from "@use/data/catalog/offers";

export default {
    name: "Offers",
    components: {
        'v-block': Block,
        'v-offer': Offer,
        'v-skeleton-cart': Card,
    },
    props: {
        id: {
            type: Number,
            default: null,
        },
        title: {
            type: String,
            default: null,
        },
        url: {
            type: String,
            default: null,
        },
        slider: {
            type: Boolean,
            default: false,
        },
        items: {
            type: Array,
            default: null,
        },
    },
    setup() {
        let offers = ref(null);
        onMounted(async () => {

        });

        const initHandler = async () => {
            let {fetchData} = useOffers();
            // console.log(fetchData);
            let data = await fetchData();
            if (data) offers.value = data;
        }

        return {
            offers,
            initHandler,
        }
    }
}
</script>

<style></style>
