<template>
    <div class="arrows">
        <div class="arrows__prev" @click="prevHandler"></div>
        <div class="arrows__next" @click="nextHandler"></div>
    </div>
</template>

<script>
    export default {
        name: "Arrows",
        setup(prop, {emit}) {

            function prevHandler() {
                emit('onPrev')
            }

            function nextHandler() {
                emit('onNext')
            }

            return {
                prevHandler,
                nextHandler,
            }
        }
    }
</script>

<style scoped>

</style>
