<template>
    <section class="slider"
             :style="style_slider"
    >
        <header v-if="$slots.header" class="slider__header">
            <slot name="header"></slot>
        </header>

        <main class="slider__main">
            <v-arrows v-if="settings.arrows"
                      @onPrev="onPrev"
                      @onNext="onNext"
            ></v-arrows>

            <div class="slider__viewport"
                 ref="viewport"
                 :style="{'padding': `${paddingViewport}px`}"
            >
                <v-preloader v-if="loading" class="slider__preloader"></v-preloader>
                <template v-if="!outside">
                    <v-points :points="quantity"
                              :index="step"
                              @onChange="onChange"
                    ></v-points>
                </template>

                <div class="slider__container" ref="container" :style="style">
                    <div class="slide slide_clone"
                         v-for="item in prev_clone"
                         :key="item"
                         :class="class_slide"
                         :style="style_slide"
                         v-html="item.innerHTML"
                    ></div>
                    <slot v-if="$slots.default" name="default"></slot>
                    <div class="slide slide_clone"
                         v-for="item in next_clone"
                         :key="item"
                         :class="class_slide"
                         :style="style_slide"
                         v-html="item.innerHTML"
                    ></div>
                </div>
            </div>
        </main>

        <footer v-if="$slots.footer || outside" class="slider__footer">
            <v-points :points="quantity"
                      :index="step"
                      class="points_static"
                      @onChange="onChange"
            ></v-points>
            <slot name="footer"></slot>
        </footer>
    </section>
</template>

<script>
import {computed, onMounted, reactive, ref, watch} from 'vue'

import Arrows from "@components/ui/Arrows";
import Points from "@components/ui/Points";
import Preloader from "@components/ui/Preloader";

import useDrag from "@use/drag";
import useResponse from "@use/response";

export default {
    name: "Slider",
    components: {
        "v-preloader": Preloader,
        "v-points": Points,
        "v-arrows": Arrows,
    },
    props: {
        slides: {
            type: Array,
            default: () => []
        },
        view: {
            type: Number,
            default: 1
        },
        response: {
            type: Object,
            default: null
        },
        loop: {
            type: Boolean,
            default: false
        },
        margin: {
            type: Number,
            default: 20
        },
        padding: {
            type: Number,
            default: 0
        },
        paddingViewport: {
            type: Number,
            default: 12
        },
        touch: {
            type: Boolean,
            default: false
        },
        points: {
            type: Boolean,
            default: false
        },
        arrows: {
            type: Boolean,
            default: true
        },
        outside: {
            type: Boolean,
            default: false
        },
        auto: {
            type: Boolean,
            default: false
        },
        activate: {
            type: Boolean,
            default: false
        },
        overflow: {
            type: Boolean,
            default: true
        },
        className: {
            type: String,
            default: 'slide'
        },
        direction: {
            type: String,
            default: 'horizontal' /** horizontal vertical **/
        },
    },
    setup(props, {emit}) {
        let step = ref(0)
        let quantity = ref(0)
        let one = ref(false)

        let offset = ref(0)
        let time = ref(0)
        let loading = ref(false)

        const viewport = ref(null)
        const container = ref(null)

        const build = ref(true);

        const settings = reactive({
            view: props.view,
            margin: props.margin,
            arrows: props.arrows,
            points: props.points,
            loop: props.loop,
            auto: props.auto,
            activate: props.activate,
            direction: props.direction,
        })

        const sizes = reactive({
            window: 0,
            viewport: 0,
            container: 0,
            slide: 0,
            step: 0,
        })

        let slides = reactive([])
        let total = ref(null);

        let prev_clone = ref([]);
        let next_clone = ref([]);

        watch(
            () => settings.view,
            (view) => cloning(view)
        )

        function cloning(view) {
            if (total.value >= view && settings.loop) {
                const start = total.value - view
                prev_clone.value = slides.slice(start, start + view)
                next_clone.value = slides.slice(0, view)
            } else {
                prev_clone.value = next_clone.value = []
            }
        }

        let {dragInit, callbacks, mouse_offset_x, drag} = useDrag(viewport, emit, 100, true, true)

        onMounted(() => {
            slides = [...container.value.children]
            total.value = slides.length;

            for (const slide of slides) {
                slide.classList.add(props.className)
            }

            build.value = !props.slides;

            // if (build.value) {
            //     slides = build.value ? [...container.value.children] : props.slides;
            //     for (const slide of slides) {
            //         slide.classList.add(props.className)
            //     }
            // } else {
            //     slides = props.slides;
            // }

            // total.value = slides.length;

            cloning(props.view);

            dragInit();
            callbacks.onSwipeRight = onPrev;
            callbacks.onSwipeLeft = onNext;

            window.addEventListener('resize', onResize);

            calculation()
            emit('onCreate', true);
        })

        const getRemainder = (total, view) => total % view !== 0 ? true : view === 1;

        /**
         * Кол-во возможных шагов
         **/
        function getQuantity() {
            /**
             * Если кол-ва слайдов меньше кол-ва показываемых слайдов
             */
            if (total.value < settings.view) return 0;

            if (props.loop) {
                if (one.value) {
                    return total.value;
                } else {
                    return (total.value / settings.view);
                }
            } else {
                if (one.value) {
                    return (total.value - settings.view) + 1;
                } else {
                    return (total.value / settings.view);
                }
            }
        }

        function calculation() {
            // Debug(props, settings, sizes, {
            //     'total': total.value,
            //     'step': step.value,
            //     'quantity': quantity.value,
            //     'drag': drag.value
            // })

            sizes.window = window.innerWidth
                || document.documentElement.clientWidth
                || document.body.clientWidth;

            if (props.response) {
                setResponsive()
            }

            one.value = getRemainder(total.value, settings.view);
            quantity.value = getQuantity();

            sizes.viewport = viewport.value.clientWidth - props.paddingViewport;
            sizes.container = props.loop
                ? (sizes.viewport + settings.margin) * (total.value + settings.view * 2)
                : (sizes.viewport + settings.margin) * total.value;

            sizes.slide = Math.round((sizes.viewport - ((settings.view - 1) * settings.margin)) / settings.view)

            sizes.step = Math.round(one.value
                ? (sizes.slide + props.margin / 2) + props.paddingViewport / 2
                : sizes.viewport + settings.margin);

            if (props.loop) {
                offset.value = quantity.value ? (sizes.slide + settings.margin) * settings.view : 0;
            }

            if (step.value >= quantity.value) step.value = 0;

            for (let slide of slides) {
                slide.style = style_slide.value;
            }
        }

        function setResponsive() {
            let response = useResponse(props.response, sizes.window);

            settings.view = response.items ? response.items : props.view;
            settings.margin = settings.view === 1 ? 0 :
                response.margin ? response.margin : props.margin;

            settings.padding = response.padding ? response.padding : props.padding;
            settings.auto = response.margin ? response.auto : props.auto;

            if (quantity.value) {
                settings.points = response.points !== undefined ? response.points : props.points;
                settings.arrows = response.arrows !== undefined ? response.arrows : props.arrows;
            } else {
                settings.points = false;
                settings.arrows = false;
            }

            settings.direction = response.direction !== undefined ? response.direction : props.direction;
        }

        function onResize() {
            calculation();
            emit('onResize', step);
        }

        const onChange = (value) => {
            if (step.value !== value) change(value)
        }

        function change(value, transition = true) {
            if (quantity.value) {
                if (!props.loop) {
                    if (value >= quantity.value) {
                        value = 0;
                    } else if (value < 0) {
                        value = quantity.value - 1;
                    }
                } else {
                    let end = null;
                    if (value >= quantity.value) {
                        value = quantity.value;
                        end = 0;
                    } else if (value < 0) {
                        value = -1;
                        end = quantity.value - 1;
                    }

                    if (end !== null) setTimeout(() => change(end, false), 300);
                }
            } else {
                value = 0;
            }

            time.value = transition ? .3 : 0;

            activate();
            step.value = value
        }

        function activate() {
            let min, max = 0;
            max = min + settings.view;

            if (one.value) {
                if (props.loop) {
                    min = settings.view + step.value;
                } else {
                    min = step;
                }
                max = min + settings.view;
            } else {
                if (props.loop) {
                    min = settings.view * (step.value + 1);
                    max = min + settings.view;
                } else {
                    min = step;
                }
            }

            // console.log(min, max, 'min - max', one.value, step.value, props.loop);

            for (let i = 0; i < total.value; ++i) {
                if (i >= min && i < max) {
                    slides[i].classList.add('slide_active');
                } else {
                    slides[i].classList.remove('slide_active');
                }
            }
        }

        const onPrev = () => change(step.value - 1);
        const onNext = () => change(step.value + 1);

        let position = computed(() => Math.round(-((step.value * sizes.step) + offset.value)) + mouse_offset_x.value);
        let size = computed(() => settings.direction === 'horizontal' ? `width` : `height`);
        let margin = computed(() => settings.direction === 'horizontal' ? `margin-right` : `margin-button`);

        let style = computed(() => {
            if (drag.value) {
                return `transition-duration: 0;
                        ${size.value}: ${sizes.container}px;
                        transform: translate(${position.value}px)`
            } else {
                return `transition-duration: ${time.value}s;
                        ${size.value}: ${sizes.container}px;
                        transform: translate(${position.value}px);`
            }
        })

        let class_slide = computed(() => {
            let result = '',
                items = slides.length ? slides[0].classList : ''
            for (const item of items) result += ` ${item}`
            return result
        })

        let style_slide = computed(() => {
            return `${size.value}: ${sizes.slide}px;
                    ${margin.value}: ${settings.margin}px;
                    opacity: 1;`
        })

        let style_viewport = computed(() => {
            return `padding: ${props.paddingViewport}px,
                    overflow: ${sizes.slide}px;`
        })

        let style_slider = computed(() => props.paddingViewport ? `margin: 0 -${props.paddingViewport}px` : '')

        return {
            loading,
            settings,

            viewport,
            container,

            step,
            prev_clone,
            next_clone,
            quantity,

            style,
            style_slider,
            style_slide,
            style_viewport,

            class_slide,
            position,

            onPrev,
            onNext,
            onChange,
        }
    }
}
</script>

<style></style>
