<template>
    <v-block
        title="Наши<br/> сотрудники"
        class="block_padding block_gray"
        url="/staff/"
        textBtn="Все сотрудники"
        :items="staff"
        :loading="loading"
        :observe="true"
        @onInit="initHandler"
    >
        <template v-slot:preloader>
            <div class="list">
                <div class="list__content list__content_three">
                    <v-skeleton-cart></v-skeleton-cart>
                    <v-skeleton-cart></v-skeleton-cart>
                    <v-skeleton-cart></v-skeleton-cart>
                </div>
            </div>
        </template>

        <template v-slot:item="slotProps">
            <v-worker
                :id="slotProps.item.id"
                :name="slotProps.item.name"
                :url="slotProps.item.link"
                :image="slotProps.item.image"
                :email="slotProps.item.email"
                :phone="slotProps.item.phone"
                :post="slotProps.item.post"
                :departments="slotProps.item.departments"
            ></v-worker>
        </template>
    </v-block>
</template>

<script>
import {ref} from "vue";

import Block from "@components/content/Block";
import Worker from "@components/staff/Worker";

import useStaff from "@use/data/staff";
import Card from "@components/skeletons/Card";

export default {
    name: "Offers",
    components: {
        'v-block': Block,
        'v-worker': Worker,
        'v-skeleton-cart': Card,
    },
    props: {
        id: {
            type: Number,
            default: null,
        },
        title: {
            type: String,
            default: null,
        },
        url: {
            type: String,
            default: null,
        },
        slider: {
            type: Boolean,
            default: false,
        },
        items: {
            type: Array,
            default: null,
        },
    },
    setup() {
        let staff = ref(null);
        let loading = ref(true);

        const initHandler = async () => {
            let {fetchData} = useStaff();
            let data = await fetchData();
            if (data) {
                loading.value = false;
                staff.value = data;
            }
        }

        return {
            staff,
            initHandler,
        }
    }
}
</script>

<style></style>
