<template>
    <v-preloader v-if="pending" class="preloader"></v-preloader>
    <v-form :form="formFeedback"
            :submitBtn="false"
            url=""></v-form>

    <v-button class="btn_full btn_center btn_three"
              :disabled="!formFeedback.valid"
              @onClick="submitHandler">{{submitLabel}}
    </v-button>

    <v-modal v-model:show="send"
             width="400"
    >
        <template v-slot:default>
            <v-thanks class="thanks_order"
                      :title="thanks.title"
                      v-model:show="send"
                      :description="thanks.description"
            ></v-thanks>
        </template>
    </v-modal>
</template>

<script>
import {ref} from "vue";

import Modal from "@components/ui/modal/Modal";
import Form from "@components/ui/forms/Form";

import useFeedback from "@use/data/feedback/ordering";
import useErrors from "@use/form/errors";
import useFeedbackForm from "@use/form/base";

import Thanks from "@components/feedback/Thanks";
import Preloader from "@components/ui/Preloader";
import Button from "@components/ui/forms/Button";
import config from "@config/config";

export default {
    name: "Full",
    components: {
        "v-form": Form,
        "v-thanks": Thanks,
        "v-preloader": Preloader,
        "v-button": Button,
        "v-modal": Modal,
    },
    props: {
        service: Number,
        offer: Number,
        worker: Number,
        type: {
            type: String,
            default: 'full'
        },
        label: {
            type: String,
            default: 'Оставить заявку',
        },
        title: {
            type: String,
            default: 'Оставить заявку',
        },
        description: {
            type: String,
            default: null,
        },
        submitLabel:{
            type: String,
            default: 'Оставить заявку',
        },
    },
    setup(props) {
        const show = ref(false);
        const pending = ref(false);
        const send = ref(false);
        const thanks = ref(config.thanks);

        const formFeedback = useFeedbackForm();
        async function submitHandler(values) {
            const {fetchData} = useFeedback({
                'name': values.fields.name?.value ?? '',
                // 'email': values.fields.email?.value ?? '',
                'phone': values.fields.phone?.value ?? '',
                // 'message': values.fields.message?.value ?? '',
                'offer_id': props.offer,
                'worker_id': props.worker,
                'service_id': props.service,
            });

            pending.value = true;

            let data = await fetchData();
            if (data?.errors) {
                useErrors(data?.errors, values.fields, values);
            }

            if (data) {
                formFeedback.clear();
                pending.value = false;
                send.value = true;
            }
        }

        return {
            show,
            send,
            pending,
            formFeedback,
            thanks,
            submitHandler,
        }
    }
}
</script>

<style></style>
