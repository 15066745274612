<template>
    <div class="worker-item">
        <div class="worker-item__detail dark">
            <div v-if="post" class="worker-item__post worker-item__post_detail">{{ post }}</div>
            <a class="worker-item__name worker-item__name_detail" :href="url">{{ name }}</a>

<!--            <v-feature v-if="phone"-->
<!--                label="Контактный телефон">-->
<!--                <a href="tel:{{ phone }}" class="feature__value">{{ phone }}</a>-->
<!--            </v-feature>-->

<!--            <v-feature v-if="email"-->
<!--                label="Email">-->
<!--                <a href="mailto:{{ email }}" class="feature__value">{{ email }}</a>-->
<!--            </v-feature>-->

            <v-button class="btn_center worker-item__feedback-btn"
                      name="Получить консультацию"
                      @onClick="clickHandler()"
            ></v-button>
        </div>

        <v-image class="worker-item__image"
                 :src="image"
                 :alt="name"
        ></v-image>

        <div class="worker-item__info">
            <div v-if="post" class="worker-item__post">{{ post }}</div>
            <a class="worker-item__name" :href="url">{{ name }}</a>
            <div v-if="departments?.length" class="worker-item__departments">
                <div v-for="department in departments"
                     class="worker-item__department"
                     :key="department">
                    {{ department.name }}
                </div>
            </div>
        </div>

        <v-modal v-model:show="show"
                 width="400"
        >
            <template v-slot:default>
                <transition name="opacity" mode="out-in">
                    <v-thanks v-if="send" class="thanks_order"
                              :title="thanks.title"
                              :description="thanks.description"
                              v-model:show="send"
                    ></v-thanks>
                    <div v-else class="modal__form">
                        <header class="modal__header"><h4>Получить консультацию</h4></header>
                        <v-preloader v-if="pending" class="preloader"></v-preloader>
                        <div class="">
                            <a class="worker-item__image" :href="url">
                                <img :src="image" :alt="name" :title="name">
                            </a>

                            <div class="worker-item__info_modal">
                                <div v-if="post" class="worker-item__post">{{ post }}</div>
                                <a class="worker-item__name" :href="url">{{ name }}</a>
                                <div v-if="departments?.length" class="worker-item__departments">
                                    <div v-for="department in departments"
                                         class="worker-item__department"
                                         :key="department">
                                        {{ department.name }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <v-form
                            :form="formFeedback"
                            :submitBtn="true"
                            submitLabel="Отправить"
                            @onSubmit="submitHandler">
                        </v-form>
                    </div>
                </transition>
            </template>
        </v-modal>
    </div>
</template>

<script>
import {ref} from "vue";

import Button from "@components/ui/forms/Button";
import Form from "@components/ui/forms/Form";
import Thanks from "@components/feedback/Thanks";
import Preloader from "@components/ui/Preloader";
import Modal from "@components/ui/modal/Modal";
import Image from "@components/ui/Image";

import useFullForm from "@use/form/full";
import useFeedback from "@use/data/feedback/ordering";
import useErrors from "@use/form/errors";
import config from "@config/config";
// import Feature from "@components/ui/Feature";

export default {
    name: "Offers",
    components: {
        'v-button': Button,
        "v-form": Form,
        "v-image": Image,
        // "v-feature": Feature,
        "v-thanks": Thanks,
        "v-preloader": Preloader,
        "v-modal": Modal,
    },
    props: {
        id: {
            type: Number,
            default: null,
        },
        name: {
            type: String,
            default: null,
        },
        image: {
            type: String,
            default: null,
        },
        email: {
            type: String,
            default: null,
        },
        phone: {
            type: String,
            default: null,
        },
        url: {
            type: String,
            default: null,
        },
        post: {
            type: String,
            default: null,
        },
        departments: {
            type: Array,
            default: () => [],
        },
    },
    setup(props) {
        const show = ref(false);
        const pending = ref(false);
        const send = ref(false);
        const thanks = ref(config.thanks);

        const clickHandler = () => {
            show.value = true;
            if (!pending.value && send.value){
                send.value = false;
                formFeedback.clear();
            }
        };

        const formFeedback = useFullForm();

        async function submitHandler(values) {
            const {fetchData} = useFeedback({
                'name': values.fields.name?.value ?? '',
                'email': values.fields.email?.value ?? '',
                'phone': values.fields.phone?.value ?? '',
                'message': values.fields.message?.value ?? '',
                'worker_id': props.id,
            });

            pending.value = true;

            let data = await fetchData();
            if (data?.errors) {
                useErrors(data?.errors, values.fields, values);
            }
            if (data) {
                formFeedback.clear();
                pending.value = false;
                send.value = true;
            }
        }

        return {
            show,
            send,
            pending,
            formFeedback,
            thanks,
            submitHandler,
            clickHandler
        }
    }
}
</script>

<style></style>
