import Arrows from "@modules/ui/arrows";
import useDrag from "@use/native/drag";

export default class Carousel {
    constructor(el = null, props = {}) {
        const _defaults = {selectors: null}

        this.options = Object.assign({}, _defaults, props);
        this.$el = el || null;
        this.radius = 800;

        if (this.$el) {
            let {callbacks: callbacksDrag} = useDrag(this.$el);
            callbacksDrag.onSwipeRight = () => this.prev();
            callbacksDrag.onSwipeLeft = () => this.next();

            const {callbacks} = Arrows(this.$el);
            callbacks.onPrev = () => this.prev();
            callbacks.onNext = () => this.next();

            this.$el.classList.add('carousel');
            this.items = this.$el.querySelectorAll(this.options.selectors);

            this.len = this.items.length;
            this.step = 0;
            this.degStep = 1 / this.len;

            this.min = (this.radius - (this.radius / 2));
            this.max = (this.radius + (this.radius / 2));

            this.render();
        }
    }

    render() {
        for (const item of this.items) {
            item.classList.add('carousel__item');
        }
        this.change(0);
    }

    prev() {
        let step = this.step - 1;
        this.step = step < 0 ? this.len - 1 : step
        this.change(this.step)
    }

    next() {
        let step = this.step + 1;
        this.step = step >= this.len ? 0 : step
        this.change(this.step)
    }

    change(step) {
        let index = 0;
        for (const item of this.items) {
            let position = index + step;
            let angle = ((this.degStep * position) * Math.PI * 2) + Math.PI / 2;
            let dx = (this.radius / 2 * Math.cos(angle));
            let dz = (this.radius * Math.sin(angle));

            item.style.transform = `translate3d(${dx}px, 0, ${dz - this.radius}px)`;
            item.style.opacity = 1 - this.normalize(Math.abs(dz - this.radius), this.min, this.max);
            item.style.zIndex = Math.floor(this.max - Math.abs(dz - this.radius));
            index++;
        }
    }

    normalize(val, min, max) {
        return (val - min) / (max - min);
    }
}
