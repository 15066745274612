import useForm from "@use/form";
import phoneField from "@use/form/fields/phone";
import nameField from "@use/form/fields/name";
import emailField from "@use/form/fields/email";
import messageField from "@use/form/fields/message";

export default function useFullForm(compact = false) {
    return useForm({
        captcha: true,
        description: null,
        compact,
        field: {
            name: nameField,
            phone: phoneField,
            email: emailField,
            message: messageField,
        },
    })
}
