<template>
    <transition name="slide">
        <aside class="banners">
            <v-banner v-for="item in items"
                :key="item"
                :title="item.name"
                :content="item.description"
                :label="item.label"
                :url="item.url"
                :image="item.image"
                :id="item.id"
            ></v-banner>
        </aside>
    </transition>
</template>

<script>
import Banner from "@components/content/banners/Banner";

export default {
    name: 'Banners',
    components: {
        'v-banner': Banner,
    },
    props: {
        items: {type: Array},
    },
}
</script>

<style lang="less"></style>
