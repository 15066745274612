import {email} from "@utils/validators";
import useTemplate from "@use/native/template";

let emailField = {
    name: 'email',
    label: 'Email',
    value: '',
    type: 'email',
    clear: false,
    required: true,
    validators: {
        email: {
            validator: email,
            message: useTemplate`Не верный формат ${'label'}`,
        },
    },
}

export default emailField
