import useForm from "@use/form";
import {required} from "@utils/validators";
import useTemplate from "@use/native/template";

import nameField from "@use/form/fields/name";
import emailField from "@use/form/fields/email";
import messageField from "@use/form/fields/message";

export default function useAddReview(compact = false) {
    nameField.required = true;
    nameField.validators = {
        required: {
            validator: required,
            message: useTemplate`${'label'} - Обязательное поле для заполнения`,
        },
    };

    emailField.required = false

    messageField.label = 'Отзыв'
    messageField.required = true

    return useForm({
        captcha: true,
        description: null,
        compact,
        field: {
            name : nameField,
            email: emailField,
            message: messageField,
        },
    })
}
