<template>
    <section class="navigation"
             @mouseleave="MouseLeaveHandler(item)"
    >
        <div class="navigation__list nav nav_main" v-if="data">
            <ul class="nav__list">
                <v-item v-for="item in data.items"
                        :class="{'nav__item_drop': item.children}"
                        :link="item.url"
                        :name="item.name"
                        :key="item"
                        @mouseover="MouseHoverHandler(item)"
                >
                </v-item>
            </ul>
        </div>

        <transition name="opacity">
            <div v-if="show && selected && selected?.children" class="navigation__drop">
                <div class="container">
                    <div class="navigation__content">
                        <transition name="opacity" mode="out-in">
                            <v-list v-if="selected.children"
                                    :items="selected.children"
                                    :key="selected"
                                    class="navigation__sub-list"
                            ></v-list>
                        </transition>

                        <transition name="slide-right" mode="out-in">
                            <v-banners v-if="selected"
                                      class="navigation__banner"
                                      :key="selected"
                                      :items="selected.banners"
                            >
                            </v-banners>
                        </transition>
                    </div>

                    <nav class="nav nav_services navigation__services">
                        <ul class="nav__list">
                            <v-item v-for="item in data.services"
                                    :link="item.url"
                                    :key="item">
                                {{ item.name }}
                            </v-item>
                        </ul>
                    </nav>
                </div>
            </div>
        </transition>
    </section>
</template>

<script>
import {onMounted, ref} from "vue";

import ItemNavigation from "@components/navigation/ItemNavigation";
import ListNavigation from "@components/navigation/ListNavigation";
import Banners from "@components/content/banners/Banners";
import useNavigation from "@use/data/core/navigation";

export default {
    name: "MainNavigation",
    components: {
        'v-item': ItemNavigation,
        'v-list': ListNavigation,
        'v-banners': Banners,
    },
    setup() {
        let data = ref(null);

        let selected = ref(null);
        let show = ref(false);
        let list_show = ref(false);

        onMounted(async () => {
            let {fetchData} = useNavigation();
            data.value = await fetchData();
        });

        const MouseHoverHandler = (item) => {
            show.value = true;
            selected.value = item;
        }

        const MouseLeaveHandler = (item) => {
            selected.value = item;
        }

        return {
            data,
            show,
            list_show,
            selected,

            MouseHoverHandler,
            MouseLeaveHandler
        }
    }
}
</script>

<style lang="less">
.navigation {
    &__list {
        height: var(--header-h);
        padding: 2rem 0;
        box-sizing: border-box;
    }

    &__content {
        display: flex;
        padding: 2rem;
        box-sizing: border-box;
    }

    &__drop {
        position: absolute;
        z-index: 999;
        top: var(--header-h);
        right: 0;
        background: var(--white);
        width: 100%;
        max-height: calc(100vh - var(--header-h));
        box-shadow: var(--shadow-lg);
        border-top: 1px solid var(--ex-lt-gray);
    }

    &__sub-list {
        transition: all .3s ease-in;
        display: flex;
        flex: 1;

        column-count: 3;
        column-rule: solid 1px var(--ex-lt-blue);
        column-gap: 3rem;
    }

    &__banner {
        width: 300px;
    }

    &__services {
        border-top: 1px solid var(--ex-lt-gray);
        height: 54px;
        padding: 1rem 3rem;
        box-sizing: border-box;
        display: flex;
        align-items: center;
    }
}
</style>
