<template>
  <div class="skeleton skeleton_card">
    <div class="skeleton__image"></div>
    <div class="skeleton__name"></div>
    <div class="skeleton__description"></div>
  </div>
</template>

<script>
export default {
    name: "SkeletonCard"
}
</script>

<style scoped>

</style>
