<template>
    <transition name="shelf">
        <section class="shelf" v-if="isShow">
            <div class="shelf__wrapper">
                <header class="shelf__header">
                    <div class="shelf__services">
                        <transition name="slide">
                            <div v-if="isBack" class="shelf__back" @click="onBack"><span>Назад</span></div>
                        </transition>

                        <transition name="slide">
                            <v-close v-if="!isBack" class="shelf__close" @click="onClose"></v-close>
                        </transition>
                    </div>

                    <transition name="slide-right" mode="out-in">
                        <a v-if="getName.name"
                           :href="getName.url"
                           :key="getName"
                           class="shelf__title"><span>{{ getName.name }}</span></a>
                    </transition>
                </header>

                <main class="shelf__content">
                    <div class="shelf__screen">
                        <ul class="shelf__list">
                            <li v-if="data.information" class=" shelf__information">
                                <v-feature v-if="data.information.phone"
                                           class="shelf__phone"
                                           label="Телефон"
                                           :value="data.information.phone"
                                >
                                    <a :href="`tel:${data.information.phone}`">{{ data.information.phone }}</a>
                                </v-feature>

                                <v-button class="shelf__callback btn btn_three btn_center"
                                          @onClick="showFormHandler"
                                >Заказать звонок
                                </v-button>

                                <v-feature v-if="data.information.email"
                                           label="Электронная почта"
                                           :value="data.information.email"
                                ></v-feature>

                                <v-feature v-if="data.information.address"
                                           label="Адрес"
                                           :value="data.information.address"
                                ></v-feature>

                                <v-feature v-if="data.information.address"
                                           label="Режим работы:"
                                ><span v-html="data.information.schedule"></span></v-feature>

                                <v-feature v-if="data.social"
                                           class="shelf__social"
                                           label="Мы в социальных сетях:"
                                >
                                    <a v-for="item in data.socials"
                                       :href="item.link"
                                       :key="item.id"
                                       :title="item.name"
                                    >
                                        <img :src="item.ico"
                                             :alt="item.name">
                                    </a>
                                </v-feature>
                            </li>

                            <li class="shelf__item"><a href="/">Главная</a></li>
                            <li class="shelf__item"
                                v-for="item in data.items"
                                :class="{'shelf__item_drop': item.children, 'shelf__item_active': activeItem(item)}"
                                :key="item"
                                @click="onChange($event, item)"
                            >
                                <a :href="item.url">{{ item.name }}</a>
                            </li>

                            <li class="shelf__item"
                                v-for="item in data.services"
                                :class="{'shelf__item_drop': item.children, 'shelf__item_active': activeItem(item)}"
                                :key="item"
                                @click="onChange($event, item)"
                            >
                                <a :href="item.url">{{ item.name }}</a>
                            </li>
                        </ul>
                    </div>

                    <transition-group name="slide" mode="out-in">
                        <div v-for="screen in screens" :key="screen" class="shelf__screen shelf__screen_child">
                            <ul class="shelf__list">
                                <li class="shelf__item"
                                    v-for="item in screen.children"
                                    :class="{'shelf__item_drop': item.children, 'shelf__item_active': activeItem(item)}"
                                    :key="item"
                                    @click="onChange($event, item)"
                                >
                                    <a :href="item.url">{{ item.name }}</a>
                                </li>
                            </ul>
                        </div>
                    </transition-group>
                </main>
            </div>

            <div class="shelf__overlay" v-show="isShow" @click="onClose"></div>
        </section>
    </transition>

    <v-modal v-model:show="showForm"
             width="400"
    >
        <template v-slot:header>
            <h4>{{ title }}</h4>
        </template>

        <template v-slot:default>
            <transition name="opacity" mode="out-in">
                <v-thanks v-if="send" class="thanks_order"
                          :title="thanks.title"
                          :description="thanks.description"
                          v-model:show="send"
                ></v-thanks>
                <div v-else class="modal__form">
                    <header class="modal__header">
                        <h4>Оставить заявку</h4>
                    </header>
                    <v-preloader v-if="pending" class="preloader"></v-preloader>
                    <v-form
                        :form="formFeedback"
                        :submitBtn="true"
                        submitLabel="Отправить"
                        @onSubmit="submitHandler"
                        url=""></v-form>
                </div>
            </transition>
        </template>
    </v-modal>
</template>

<script>
import {computed, onMounted, onUnmounted, reactive, ref, watch} from "vue";
import {useStore} from 'vuex'

import Close from "@components/ui/Close";
import Feature from "@components/ui/Feature";
import Button from "@components/ui/forms/Button";
import Modal from "@components/ui/modal/Modal";
import Form from "@components/ui/forms/Form";
import Thanks from "@components/feedback/Thanks";
import Preloader from "@components/ui/Preloader";

import useFeedbackForm from "@use/form/base";
import useCommon from "@use/data/common";
import useFeedback from "@use/data/feedback/ordering";
import useErrors from "@use/form/errors";

import config from "@config/config";

export default {
    name: "Shelf",
    components: {
        'v-close': Close,
        'v-feature': Feature,
        'v-button': Button,
        "v-modal": Modal,
        "v-form": Form,
        "v-preloader": Preloader,
        "v-thanks": Thanks,
        // 'v-search': Search
    },
    props: {},
    setup() {
        const store = useStore()

        let path = window.location.pathname,
            pathList = path.split('/'),
            rootLevel = `/${pathList[1]}`

        let data = ref(null);
        const step = ref(0),
            screens = reactive([]),

            init = ref(false),
            back = ref(false),
            showForm = ref(false),
            title = ref('');

        const pending = ref(false);
        const send = ref(false);
        const thanks = ref(config.thanks);
        const formFeedback = useFeedbackForm();

        async function submitHandler(values) {
            const {fetchData} = useFeedback({
                'name': values.fields.name?.value ?? "",
                'phone': values.fields.phone?.value ?? "",
            });

            pending.value = true;

            let data = await fetchData();
            if (data?.errors) {
                useErrors(data?.errors, values.fields, values);
            }
            if (data) {
                formFeedback.clear();
                pending.value = false;
                send.value = true;
            }
        }

        onMounted(async () => {
            document.addEventListener("keydown", onKeyDown);
        })

        onUnmounted(() => {
            document.removeEventListener("keydown", onKeyDown)
        })

        let isShow = computed(() => store.getters['shelf/isShow'])
        let loaded = computed(() => store.getters['shelf/loaded'])
        let isBack = computed(() => back.value)

        let getName = computed(() => {
            let url = screens[screens.length - 1] ? screens[screens.length - 1].url : null,
                name = screens[screens.length - 1] ? screens[screens.length - 1].name : null;

            return {
                'url': url,
                'name': name,
            }
        })

        watch(isShow,
            async (value) => {
                if (value) {
                    if (!init.value) {
                        if (!data.value) {
                            let {fetchData} = useCommon();
                            data.value = await fetchData();
                        }
                        init.value = true;
                    }
                    document.body.style.overflow = "hidden";
                } else {
                    document.body.style.overflow = null;
                }
            }
        )

        function activeItem(item) {
            if (item.url === path) return true;
            if (item.url === rootLevel) return true;
            return item.active || false;
        }

        function onKeyDown(e) {
            if (isShow && e.keyCode === 27) onClose();
        }

        function onClose() {
            reset();
            store.dispatch('shelf/show', false);
        }

        function onBack() {
            screens.pop();
            step.value = step.value - 1;
            back.value = step.value !== 0;
        }

        function showFormHandler() {
            showForm.value = true;
        }

        function reset() {
            screens.length = 0;

            step.value = 0;
            back.value = false;
        }

        function onChange(e, item) {
            e.preventDefault()
            if (item.children) {
                back.value = true;
                screens[step.value] = {
                    'name': item.name,
                    'children': item.children ? item.children : null,
                    'url': item.url,
                };

                step.value = step.value + 1;
            } else {
                window.location.href = item.url;
            }
        }

        return {
            showForm,
            thanks,
            pending,
            send,

            isShow,
            isBack,
            loaded,

            formFeedback,
            getName,
            back,

            title,
            data,
            screens,
            step,

            submitHandler,

            showFormHandler,
            activeItem,

            onChange,
            onClose,
            onBack,
        }
    },
}

</script>

<style></style>
