<template>
    <div class="nav nav_column">
        <ul class="nav__list">
            <v-item v-for="item in items"
                    :linkClass="{'nav__link_header' : item.children}"
                    :link="item.url"
                    :items="item.children"
                    :key="item">
                {{ item.name }}
                <template v-slot:items="slotProps">
                    <ul class="nav___sub-list">
                        <v-item v-for="item in slotProps.items"
                                :link="item.url"
                                :key="item">
                            {{ item.name }}
                        </v-item>
                    </ul>
                </template>
            </v-item>
        </ul>
    </div>
</template>

<script>
import ItemNavigation from "@components/navigation/ItemNavigation";

export default {
    name: "ListNavigation",
    components: {
        'v-item': ItemNavigation,
    },
    props: {
        items: {
            type: Array,
            default: null
        },
    },
    setup() {
    }
}
</script>

<style scoped>

</style>
