import {createApp} from 'vue'
import {store} from './store'

import {clickOutside} from "@/directive/clickOutSide";

import Shelf from "@components/shelf/Shelf";
import AddReview from "@components/relations/AddReview";
import Offers from "@components/catalog/offers/Offers";
import MainNavigation from "@components/navigation/MainNavigation";
import FeedbackButton from "@components/feedback/FeedbackButton";
import FullButton from "@components/feedback/FullButton";
import Full from "@components/feedback/Full";
import Staff from "@components/staff/Staff";
import Feedback from "@components/feedback/Feedback";

import Carousel from "@modules/ui/carusel";
// import SliderLeap from "@modules/ui/sliders/slider";

window.addEventListener('load', async () => {
    let $body = document.querySelector('body');
    let $main = $body.querySelector('main.layout');

    let $header = $body.querySelector('#header');
    let headerHeight = $header.clientHeight;

    // let width = null;
    // let height = null;

    // let $content = $main.querySelector('#content');
    // let $sidebar = $main.querySelector('#sidebar');

    let resizeTimeOutId = null;

    function resizeHandler() {
        // width = window.innerWidth;
        // height = window.innerHeight;
    }

    resizeHandler();

    window.addEventListener('resize', () => {
        if (resizeTimeOutId) clearTimeout(resizeTimeOutId);
        resizeTimeOutId = setTimeout(() => resizeHandler(), 150);
    })

    function scrollHandler() {
        if (window.pageYOffset > headerHeight) {
            $header.classList.add('header_fixed');
        } else {
            $header.classList.remove('header_fixed');
        }
    }

    document.addEventListener('scroll', scrollHandler)
    scrollHandler();

    /**Slider*/
    // let $slider = document.querySelector('#slider-main');
    // if ($slider) {
    //     const datasetSlider = $slider.dataset ?? {};
    //     const option = JSON.parse(datasetSlider.option);
    //
    //     new SliderLeap($slider, {
    //         ...option,
    //         response: {
    //             0: {arrows: false,},
    //             960: {arrows: true,}
    //         },
    //     })
    // }


    /**Staff*/
    // let $sliderStaff = document.querySelector('#slider-staff');
    // if ($sliderStaff) {
    //     new SliderLeap($sliderStaff, {
    //         margin: 10,
    //         paddingViewport: 12,
    //         loop: false,
    //         response: {
    //             0: {arrows: false, items: 1},
    //             960: {arrows: true, items: 3},
    //             1600: {arrows: true, items: 4}
    //         },
    //     })
    // }

    /** Relations */
    // const $galleries = $main.querySelectorAll(".galleries-app");
    // if ($galleries.length) {
    //     createApp(GalleryContent, {...$galleries.dataset ?? {}}).mount($galleries);
    // }

    let $offersBlock = document.querySelector('#offers-block');
    if ($offersBlock) {
        createApp(Offers, {...$offersBlock.dataset ?? {}}).mount($offersBlock);
    }

    let $staffBlock = document.querySelector('#staff-block');
    if ($staffBlock) {
        createApp(Staff, {...$staffBlock.dataset ?? {}}).mount($staffBlock);
    }

    /** Navigation */
    createApp(MainNavigation)
        .directive("click-outside", clickOutside)
        .mount('#nav-main');

    let $navBtn = $header.querySelector('#nav-btn');
    if ($navBtn) {
        $navBtn.addEventListener('click', () => {
            store.dispatch('shelf/show', true);
        })
    }

    createApp(Shelf)
        .use(store)
        .directive("click-outside", clickOutside)
        .mount('#shelf');

    /** Relations */
    const $reviews = $main.querySelector("#reviews.reviews_carousel");
    if ($reviews) {
        const $reviewsList = $reviews.querySelector("#reviews-app");
        const $preloader = $reviewsList.querySelector(".skeleton");

        $preloader.style.display = 'none';
        new Carousel($reviewsList, {
            selectors: '.review-item'
        })
    }

    const $addReview = document.querySelector("#add-review");
    if ($addReview) createApp(AddReview).mount($addReview);

    const $callback = document.querySelector("#callback");
    if ($callback) {
        createApp(FeedbackButton, {...$callback.dataset ?? {}})
            .directive("click-outside", clickOutside)
            .mount($callback);
    }

    const $feedbackButton = document.querySelector("#feedback-btn");
    if ($feedbackButton) {
        createApp(FeedbackButton, {...$feedbackButton.dataset ?? {}})
            .directive("click-outside", clickOutside)
            .mount($feedbackButton);
    }

    const $fullButton = document.querySelectorAll(".full-app");
    if ($fullButton.length) {
        for (const $item of [...$fullButton]) {
            let dataset = $item.dataset ?? {};
            createApp(FullButton, {...dataset})
                .directive("click-outside", clickOutside)
                .mount($item);
        }
    }

    const $feedbackForm = document.querySelector("#feedback");
    if ($feedbackForm) {
        createApp(Feedback, {...$feedbackForm.dataset ?? {}})
            .directive("click-outside", clickOutside)
            .mount($feedbackForm);
    }

    const $fullForm = document.querySelector("#full");
    if ($fullForm) {
        // console.log($fullForm);
        createApp(Full, {...$fullForm.dataset ?? {}})
            .directive("click-outside", clickOutside)
            .mount($fullForm);
    }
})
