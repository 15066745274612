<template>
    <section class="block" ref="block">
        <div class="block__container">
            <header v-if="$slots.header || title" class="block__header block__header_row">
                <slot v-if="$slots.header || title" name="header">
                    <h2 class="block__title" v-html="title"></h2>
                    <v-button v-if="url" :href="url" class="block__btn btn_default btn_sm">{{ textBtn }}</v-button>
                </slot>
            </header>

            <main class="block__content">
                <slot v-if="$slots.content" name="content"></slot>
                <template v-else>
                    <template v-if="!items && !initialization && loading">
                        <slot v-if="$slots.preloader" name="preloader">
                            <v-preloader></v-preloader>
                        </slot>
                    </template>
                    <template v-else>
                        <template v-if="slider && items?.length > view">
                            <v-slider
                                :slides="items"
                                :outside="true"
                                :response="{
                                    0:{items: 1,},
                                    540:{items: 2,},
                                    960:{items: 3,},
                                    1600:{items: 4,},
                                }"
                            >
                                <template v-for="item in items"
                                          :key="item.id"
                                >
                                    <slot v-if="$slots.item" name="item" :item="item"></slot>
                                </template>
                            </v-slider>
                        </template>
                        <div v-else class="block__list block__list_grid">
                            <template>
                                <template v-for="item in items"
                                          :key="item.id"
                                >
                                    <slot v-if="$slots.item" name="item" :item="item"></slot>
                                </template>
                            </template>
                        </div>
                    </template>

                    <transition name="opacity">
                        <v-button v-if="more" @onClick="loadMoreHandler"
                                  class="products__more btn_full btn_secondary center btn_outline">
                            <span v-if="isLoaded"><v-preloader></v-preloader></span>
                            <template v-else>Загрузить еше</template>
                        </v-button>
                    </transition>
                </template>
            </main>

            <footer>
                <slot v-if="$slots.footer" name="footer"></slot>
            </footer>
        </div>
    </section>
</template>

<script>
import {onMounted, ref} from "vue";

import Button from "@components/ui/forms/Button";
import Preloader from "@components/ui/Preloader";
import Slider from "@components/ui/Slider";

/**
 * Пример переопределение Option template из родительского компонента
 *  <template v-slot:item="slotProps">
 *      <div>{{ slotProps.item.name }}</div>
 *  </template>
 */

export default {
    name: "Block",
    components: {
        'v-slider': Slider,
        "v-button": Button,
        "v-preloader": Preloader,
    },
    props: {
        lazy: {
            type: Boolean,
            default: false,
        },
        title: {
            type: String,
            default: null,
        },
        url: {
            type: String,
            default: null,
        },
        textBtn: {
            type: String,
            default: null,
        },
        items: {
            type: Array,
            default: null,
        },
        slider: {
            type: Boolean,
            default: true,
        },
        view: {
            type: Number,
            default: 3,
        },
        more: {
            type: Boolean,
            default: false,
        },
        loading: {
            type: Boolean,
            default: true,
        },
        observe: {
            type: Boolean,
            default: false,
        },
    },
    setup(props, {emit}) {
        let block = ref(null);
        let initialization = ref(false);

        const options = {
            // root: document.querySelector( '#layout' ),
            rootMargin: '0px',
            threshold: [0, 0.5]
        };

        const observer = new IntersectionObserver((entries, observer) => {
            console.log(observer);
            entries.forEach((entry) => {
                const {target, isIntersecting} = entry;

                /**
                 entry.boundingClientRect
                 entry.isIntersecting
                 entry.intersectionRatio
                 entry.intersectionRect
                 entry.rootBounds
                 entry.target
                 entry.time
                 **/

                // console.log(time);
                console.log(isIntersecting);
                // console.log(intersectionRect);
                // console.log(intersectionRatio);

                emit('onIntersecting', isIntersecting);

                if (isIntersecting) {
                    if (!initialization.value) {
                        initialization.value = true;
                        emit('onInit');
                        observer.unobserve(target);
                    }

                    target.classList.add('some-class');
                } else {
                    target.classList.remove('some-class');
                }
            });
        }, options);


        onMounted(() => {
            if (props.observe && block.value) observer.observe(block.value);
        })

        const loadMoreHandler = () => emit('loadMore');

        return {
            block,
            initialization,
            loadMoreHandler
        }
    }
}
</script>

<style></style>
