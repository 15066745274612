<template>
    <li class="nav__item">
        <a :href="link" class="nav__link" :class="linkClass">
            <slot v-if="$slots.default || name" name="default">{{ name }}</slot>
        </a>
        <slot v-if="$slots.items" name="items" :items="items"></slot>
    </li>
</template>

<script>
export default {
    name: "ItemNavigation",
    props: {
        name: {
            type: String,
            default: null
        },
        link: {
            type: String,
            default: null
        },
        linkClass: {
            type: String,
            default: null
        },
        items: {
            type: String,
            default: null
        },
    },
    setup() {
    }
}
</script>

<style scoped>

</style>
