<template>
    <v-button class="btn_primary center"
              @onClick="clickHandler()"
              :name="label"
    ></v-button>

    <v-modal v-model:show="show"
             width="400"
    >
        <template v-slot:default>
            <transition name="opacity" mode="out-in">
                <v-thanks v-if="send" class="thanks_order"
                          :title="thanks.title"
                          :description="thanks.description"
                          v-model:show="send"
                ></v-thanks>
                <div v-else class="modal__form">
                    <header class="modal__header">
                        <h4>{{ title }}</h4>
                    </header>
                    <v-preloader v-if="pending" class="preloader"></v-preloader>
                    <v-form
                        :form="formAddReview"
                        :submitBtn="true"
                        submitLabel="Отправить"
                        @onSubmit="submitHandler"
                        url=""></v-form>
                </div>
            </transition>
        </template>
    </v-modal>
</template>

<script>
import {ref} from "vue";

import Button from "@components/ui/forms/Button";
import Modal from "@components/ui/modal/Modal";
import Form from "@components/ui/forms/Form";
import Thanks from "@components/feedback/Thanks";
import Preloader from "@components/ui/Preloader";

import useErrors from "@use/form/errors";
import useReview from "@use/data/relations/review";
import useAddReview from "@use/form/addReview";

export default {
    name: "FeedbackButton",
    components: {
        "v-form": Form,
        "v-thanks": Thanks,
        "v-button": Button,
        "v-preloader": Preloader,
        "v-modal": Modal,
    },
    props: {
        service: Number,
        worker: Number,
        label: {
            type: String,
            default: 'Оставить отзыв',
        },
        title: {
            type: String,
            default: 'Оставить отзыв',
        },
        description: {
            type: String,
            default: null,
        },
        textButton: {
            type: String,
            default: null,
        },
    },
    setup: function (props) {
        const show = ref(false);
        const pending = ref(false);
        const send = ref(false);
        const thanks = {
            title: "Спасибо за ваш отзыв!",
            description: "Ваше мнение очень важно для нас"
        }

        const clickHandler = () => {
            show.value = true;
            if (!pending.value && send.value) {
                send.value = false;
                formAddReview.clear();
            }
        };

        const formAddReview = useAddReview();

        async function submitHandler(values) {
            const {fetchData} = useReview({
                'author': values.fields.name?.value ?? "",
                'email': values.fields.email?.value ?? "",
                'content': values.fields.message?.value ?? "",
                'worker_id': props.worker,
                'service_id': props.service,
            });

            pending.value = true;

            let data = await fetchData();
            if (data?.errors) {
                useErrors(data?.errors, values.fields, values);
            }
            if (data) {
                formAddReview.clear();
                pending.value = false;
                send.value = true;
            }
        }

        return {
            show,
            send,
            pending,
            formAddReview,
            thanks,
            submitHandler,
            clickHandler
        }
    }
}
</script>

<style></style>
