<template>
    <v-button class="btn_three btn_full center"
              @onClick="clickHandler()"
              :name="label"
    ></v-button>

    <v-modal v-model:show="show"
             width="400"
    >
        <template v-slot:default>
            <transition name="opacity" mode="out-in">
                <v-thanks v-if="send" class="thanks_order"
                          :title="thanks.title"
                          :description="thanks.description"
                          v-model:show="send"
                ></v-thanks>
                <div v-else class="modal__form">
                    <header class="modal__header">
                        <h4>{{ title }}</h4>
                    </header>
                    <v-preloader v-if="pending" class="preloader"></v-preloader>
                    <v-form
                        :form="formFeedback"
                        :submitBtn="true"
                        submitLabel="Отправить"
                        @onSubmit="submitHandler"
                        url=""></v-form>
                </div>
            </transition>
        </template>
    </v-modal>
</template>

<script>
import {ref} from "vue";

import Button from "@components/ui/forms/Button";
import Modal from "@components/ui/modal/Modal";
import Form from "@components/ui/forms/Form";

import useFeedbackForm from "@use/form/base";
import useFeedback from "@use/data/feedback/ordering";
import useErrors from "@use/form/errors";
import Thanks from "@components/feedback/Thanks";
import Preloader from "@components/ui/Preloader";

import config from "@config/config";

export default {
    name: "FeedbackButton",
    components: {
        "v-form": Form,
        "v-thanks": Thanks,
        "v-button": Button,
        "v-preloader": Preloader,
        "v-modal": Modal,
    },
    props: {
        service: Number,
        offer: Number,
        worker: Number,
        label: {
            type: String,
            default: 'Оставить заявку',
        },
        title: {
            type: String,
            default: 'Оставить заявку',
        },
        description: {
            type: String,
            default: null,
        },
        textButton: {
            type: String,
            default: null,
        },
    },
    setup(props) {
        const show = ref(false);
        const pending = ref(false);
        const send = ref(false);
        const thanks = ref(config.thanks);

        const clickHandler = () => {
            show.value = true;
            if (!pending.value && send.value){
                send.value = false;
                formFeedback.clear();
            }
        };

        const formFeedback = useFeedbackForm();
        async function submitHandler(values) {
            const {fetchData} = useFeedback({
                'name': values.fields.name?.value ?? "",
                'phone': values.fields.phone?.value ?? "",
                'offer_id': props.offer,
                'worker_id': props.worker,
                'service_id': props.service,
            });

            pending.value = true;

            let data = await fetchData();
            if (data?.errors) {
                useErrors(data?.errors, values.fields, values);
            }
            if (data) {
                formFeedback.clear();
                pending.value = false;
                send.value = true;
            }
        }

        return {
            show,
            send,
            pending,
            formFeedback,
            thanks,
            submitHandler,
            clickHandler
        }
    }
}
</script>

<style></style>
